import React from "react";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import ServicesPage from "../../components/ServicesPage/ServicesPage";
import uxuidesign from "../../images/pages/ux-ui-design.svg";

const content = {
  pageTitle: "UX/UI Design",
  subtitle: "Sticky Web Design That Engages and Excites",
  imgLink: uxuidesign,
  imgAlt: "illustration showing user experience and interface design",
  body:
    "<h2>DID YOU KNOW THAT MOST PEOPLE SPEND LESS THAN 15 SECONDS ON A WEBSITE?</h2><p>That’s not an engaging, sticky experience at all. And when it comes to web design or app experiences, you want users to be stuck on it like glue. Your UI/UX should be intuitive, but it should also be interesting enough for your users to keep coming back for more. Sticky websites depend on sticky experiences. After all, what’s the use of a feature-rich, informative website when users leave it in just under 15 seconds?</p><h2>HOW WE CAN HELP</h2><p>If you don’t want to get lumped in the category of “beautiful site but too clunky, too complicated, and too confusing to use,” then good news. Serbay Digital will walk you through the concept, design, and user interface, so you don’t get stuck with just another pretty website. If you have an existing website, we can map out the current user experience for you and give our recommendations based on industry best practices instead.</p><p>When it comes to websites or mobile apps, user experiences matter most.</p><p>That’s why at Serbay Digital, we don’t merely design websites: we design experiences. Our web design solutions are all about providing the best user experience for your visitors. We make your brand and business look good.</p><h2>WHY US</h2><p>The best part? You don’t have to spend so much. Serbay Digital offers premium-quality web design services at a fair price. You can also expect us to be open and transparent about the line items on our contract from start to finish. Excellent website design with a fantastic user interface should be made available to all.</p>",
};

const uxuiDesignPage = () => (
  <Layout>
    <SEO
      title="Work With Serbay Digital: Web Design Experts Who Increase Your Sales"
      description="Your site needs a web design that engages and excites. Deliver the best user experience to your visitors with the help of the Serbay Digital team.
"
    />
    <ServicesPage {...content}></ServicesPage>
  </Layout>
);

export default uxuiDesignPage;
